import { Loader2 } from "lucide-react"
import { ReactNode } from "react"

export default function ContentLoader({
  children,
  loading,
}: {
  children: ReactNode
  loading: boolean
}) {
  if (loading) {
    return (
      <div className="h-app flex justify-center items-center w-full">
        <Loader2 className="h-10 w-10 animate-spin text-muted-foreground" />
      </div>
    )
  }

  return <>{children}</>
}
